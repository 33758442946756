<template>
  <el-dialog
    :title="!dataForm.id ? '新增' : !disabled ? '修改' : '查看'"
    :close-on-click-modal="false"
    append-to-body
    :visible.sync="visible"
  >
    <el-form
      ref="dataForm"
      :model="dataForm"
      :rules="dataRule"
      label-width="120px"
      @keyup.enter.native="dataFormSubmit()"
    >
      <el-form-item label="充值活动标题" prop="title">
        <el-input
          v-model="dataForm.title"
          :disabled="disabled"
          placeholder="充值活动标题"
        />
      </el-form-item>
      <el-form-item label="充值活动赠送金额上限" prop="limitMoney">
        <el-input-number
          v-model="dataForm.limitMoney"
          :min="0"
          :precision="2"
          :disabled="disabled"
          placeholder="充值活动赠送金额上限"
        />
      </el-form-item>
      <el-form-item label="有效期开始时间" prop="beginTime">
        <el-date-picker
          v-model="dataForm.beginTime"
          type="datetime"
          :disabled="disabled"
          :picker-options="datePicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="有效期开始时间"
        />
      </el-form-item>
      <el-form-item label="有效期结束时间" prop="endTime">
        <el-date-picker
          v-model="dataForm.endTime"
          type="datetime"
          :disabled="disabled"
          :picker-options="datePicker"
          value-format="yyyy-MM-dd HH:mm:ss"
          placeholder="有效期结束时间"
        />
      </el-form-item>
      <el-form-item label="充值优惠面额" prop="rechargeRuleIds">
        <el-select
          v-model="dataForm.rechargeRuleIds"
          :disabled="disabled"
          @change="getRechargeRule"
          clearable
          multiple
          filterable
          placeholder="请选择"
          style="width: 100%"
        >
          <el-option
            v-for="rechargeRule in rechargeRuleList"
            :key="rechargeRule.id"
            :label="rechargeRule.rechargeAmount"
            :value="rechargeRule.id"
          />
        </el-select>
      </el-form-item>
      <el-table :data="dataForm.rechargeActivityRules">
        <el-table-column
          prop="rechargeAmount"
          header-align="center"
          align="center"
          label="充值面额"
        ></el-table-column>
        <el-table-column
          prop="gifAmount"
          header-align="center"
          align="center"
          label="充值赠送金额"
        >
          <template slot-scope="scope">
            <el-input-number
              v-model="scope.row.giftAmount"
              :disabled="disabled"
              :min="0"
              size="small"
              placeholder="充值赠送金额"
              style="width: 100%"
            />
          </template>
        </el-table-column>
      </el-table>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取消</el-button>
      <el-button v-if="!disabled" type="primary" @click="dataFormSubmit()"
        >确定</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import { getRechargeRuleList } from '@/utils/options';
import { keys, pick } from 'lodash';

export default {
  data() {
    let validateMoney = (rule, value, callback) => {
      if (
        !/(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(
          value,
        )
      ) {
        callback(new Error('格式有误'));
      } else {
        callback();
      }
    };
    return {
      disabled: false,
      visible: false,
      dataForm: {
        id: 0,
        title: '',
        limitMoney: 1,
        beginTime: '',
        endTime: '',
        rechargeRuleIds: [],
        rechargeActivityRules: [],
      },
      dataRule: {
        title: [
          {
            required: true,
            message: '充值活动标题不能为空',
            trigger: 'blur',
          },
        ],
        limitMoney: [
          {
            required: true,
            message: '充值活动优惠上限不能为空',
            trigger: 'blur',
          },
        ],
        beginTime: [
          {
            required: true,
            message: '有效期开始时间不能为空',
            trigger: 'blur',
          },
        ],
        endTime: [
          {
            required: true,
            message: '有效期结束时间不能为空',
            trigger: 'blur',
          },
        ],
        rechargeRuleIds: [
          {
            required: true,
            message: '充值优惠面额不能为空',
            trigger: 'blur',
          },
        ],
      },
      rechargeRuleList: [],
      datePicker: this.picker(),
    };
  },
  created() {
    this.getRechargeRuleList();
  },
  methods: {
    picker() {
      return {
        // 可选时间大于等于当前时间
        disabledDate(time) {
          return time.getTime() < Date.now();
        },
      };
    },
    init(id, disabled) {
      this.disabled = disabled;
      this.dataForm.id = id || '';
      this.visible = true;
      this.dataForm.rechargeActivityRules = [];
      this.$nextTick(() => {
        this.$refs['dataForm'].resetFields();
        if (this.dataForm.id) {
          this.$http({
            url: `/sys/rechargeActivity/info/${this.dataForm.id}`,
            method: 'get',
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.dataForm = pick(data.rechargeActivity, keys(this.dataForm));
              // this.dataForm = { ...this.dataForm, ...data.rechargeActivity };
            }
          });
        }
      });
    },
    // 表单提交
    dataFormSubmit() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          this.$http({
            url: `/sys/rechargeActivity/${
              !this.dataForm.id ? 'save' : 'update'
            }`,
            method: 'post',
            data: this.dataForm,
          }).then(({ data }) => {
            if (data && data.code === 0) {
              this.$message({
                message: '操作成功',
                type: 'success',
                duration: 1500,
              });
              this.visible = false;
              this.$emit('refreshDataList');
            }
          });
        }
      });
    },
    getRechargeRuleList() {
      getRechargeRuleList().then(({ data }) => {
        if (data && data.code === 0) {
          this.rechargeRuleList = data.list;
        }
      });
    },
    getRechargeRule() {
      let rechargeRuleIds = this.dataForm.rechargeActivityRules.map((item) => {
        return item.rechargeRuleId;
      });
      const _ = require('lodash');

      // 筛选出不同的元素
      const uniqueInArray1 = _.differenceWith(
        rechargeRuleIds,
        this.dataForm.rechargeRuleIds,
        _.isEqual,
      );
      const uniqueInArray2 = _.differenceWith(
        this.dataForm.rechargeRuleIds,
        rechargeRuleIds,
        _.isEqual,
      );
      // uniqueInArray1有值，则代表删除了优惠券
      if (uniqueInArray1.length > 0) {
        const index = this.dataForm.rechargeActivityRules.findIndex(
          (item) => item.rechargeRuleId === uniqueInArray1[0],
        );
        this.dataForm.rechargeActivityRules.splice(index, 1);
      }
      // uniqueInArray2有值，则代表新增了优惠券
      if (uniqueInArray2.length > 0) {
        this.dataForm.rechargeActivityRules.push({
          rechargeRuleId: uniqueInArray2[0],
          rechargeAmount: this.rechargeRuleList.find(
            (item) => item.id === uniqueInArray2[0],
          ).rechargeAmount,
          giftAmount: 0,
        });
      }
    },
  },
};
</script>
